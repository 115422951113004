import React from "react";
import { TypeAnimation } from "react-type-animation";
import { Typography } from "@material-tailwind/react";
import { Element } from "react-scroll";

import sgFlag from "../../assets/sg_flag.jpg";
import SocialIcons from "../SocialIcons/SocialIcons";
import "./Home.css";

const Home = () => {
  return (
    <Element name="home">
      <div
        className="
                flex h-screen flex-col items-center
                justify-center gap-10 p-5
                lg:flex-row lg:gap-20"
      >
        <div className="text-center lg:text-left">
          <Typography
            variant="h1"
            color="blue-gray"
            className="
                            mb-2 text-4xl font-semibold
                            lg:mb-0 lg:text-5xl"
          >
            Hi, I am Zheng Ting,
          </Typography>
          <TypeAnimation
            sequence={[
              "a Software Engineer.",
              1000,
              "a Cloud Engineer.",
              1000,
              "a DevOps enthusiast.",
              1000,
              "a problem solver.",
              1000,
            ]}
            wrapper="span"
            speed={50}
            repeat={Infinity}
            className="text-2xl lg:text-3xl"
          />
          <Typography color="blue-gray" className="mt-5">
            A Software Engineering undergraduate from Singapore.{" "}
            <img className="flag" src={sgFlag} alt="Singapore flag" />
          </Typography>
          <SocialIcons />
        </div>
        <div className="self-portrait self-portrait-home-size order-first lg:order-last"></div>
      </div>
    </Element>
  );
};

export default Home;
