import React from "react";
import ProgressBar from "react-scroll-progress-bar";

import "./App.css";
import Home from "./components/Home/Home";
import Content from "./components/Content/Content";

function App() {
  return (
    <div className="App">
      <ProgressBar bgcolor="#60a5fa"/>
      <Home />
      <Content />
    </div>
  );
}

export default App;
