import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  Chip,
  Typography,
} from "@material-tailwind/react";

const Project = ({
  thumbnailSrc,
  projectTitle,
  projectUrl,
  projectDescription,
  projectStack,
}) => {
  return (
    <Card
      role="button"
      onClick={() => {
        if (projectUrl != null) window.open(projectUrl, "_blank");
      }}
      className="
                mx-auto mt-5 max-w-screen-lg scale-90
                transform cursor-pointer flex-col  border bg-transparent
                shadow-none transition-all duration-200 hover:scale-100 hover:bg-blue-50 hover:shadow-lg
                lg:mx-0 lg:w-9/12 lg:w-full lg:scale-100
                lg:flex-row lg:border-none lg:hover:scale-105"
    >
      <CardHeader
        shadow={false}
        floated={false}
        color="transparent"
        className="w-100 order-first h-auto shrink-0 p-4 lg:w-4/12"
      >
        <img
          src={thumbnailSrc}
          alt={`Thumbnail of ${projectTitle}`}
          className="rounded object-cover shadow"
        />
      </CardHeader>
      <CardBody className="flex flex-col justify-between lg:text-left">
        <div>
          <Typography variant="h3" color="gray" className="mb-4">
            {projectTitle}
          </Typography>
          <Typography color="gray" className="mb-8 font-normal">
            {projectDescription}
          </Typography>
        </div>
        <Typography color="gray" className="mt-4">
          <div
            className="
                        flex flex-wrap justify-center
                        lg:items-start lg:justify-start"
          >
            {projectStack.map((stack, index) => {
              return (
                <Chip
                  key={index}
                  variant="outlined"
                  color="blue"
                  value={stack}
                  className="mr-2 mt-2"
                />
              );
            })}
          </div>
        </Typography>
      </CardBody>
    </Card>
  );
};

Project.propTypes = {
  thumbnailSrc: PropTypes.string.isRequired,
  projectTitle: PropTypes.string.isRequired,
  projectUrl: PropTypes.string,
  projectDescription: PropTypes.string.isRequired,
  projectStack: PropTypes.array.isRequired,
};

export default Project;
