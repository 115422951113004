import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  CardHeader,
  Chip,
  Typography,
} from "@material-tailwind/react";

const IndividualExperience = ({
  from,
  to,
  company,
  position,
  type,
  description,
  stack,
}) => {
  return (
    <Card
      className="
            mx-auto max-w-screen-lg scale-90 transform
            flex-col border bg-transparent
            shadow-none transition-all duration-200 hover:scale-100 hover:bg-blue-50 hover:shadow-lg lg:mx-0
            lg:mt-5 lg:w-9/12 lg:w-full lg:scale-100
            lg:flex-row lg:border-none lg:hover:scale-105"
    >
      <CardHeader
        shadow={false}
        floated={false}
        color="transparent"
        className={`w-100 order-first hidden shrink-0 p-4 lg:block lg:w-4/12`}
      >
        <Typography variant="h6" color="gray">
          {from} - {to}
        </Typography>
      </CardHeader>
      <CardBody className="flex flex-col justify-between lg:text-left">
        <div>
          <Typography variant="h3" color="gray" className="mb-4">
            {company}
          </Typography>
          <Typography variant="h5" color="gray">
            {position}
          </Typography>
          <Typography color="gray" className="mb-4 font-normal">
            {type}
          </Typography>

          <Typography
            color="gray"
            className="mb-4 block lg:hidden"
          >
            {from} - {to}
          </Typography>
          <Typography color="gray" className="mb-8 font-normal">
            {description}
          </Typography>
        </div>
        <Typography color="gray" className="mt-4">
          <div className="flex flex-wrap justify-center lg:items-start lg:justify-start">
            {stack.map((stack, index) => {
              return (
                <Chip
                  key={index}
                  variant="outlined"
                  color="blue"
                  value={stack}
                  className="mr-2 mt-2"
                />
              );
            })}
          </div>
        </Typography>
      </CardBody>
    </Card>
  );
};

IndividualExperience.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  stack: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default IndividualExperience;
