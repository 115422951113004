import React from "react";

import IndividualExperience from "./IndividualExperience";
import Listing from "../Listing/Listing";

const ExperienceListing = () => {
  const experiences = [
    {
      from: "July 2019",
      to: "May 2020",
      company: "KLASS Engineering & Solutions",
      position: "Software Engineer (Video Analytics)",
      type: "Internship",
      description:
        "Developed an all-in-one machine learning platform, catered towards simplifying the process of labelling, training, assessing and visualising a model from scratch.",
      stack: [
        "React",
        "Docker",
        "Python",
        "Go",
        "AntDesign",
        "Cypress",
        "Redis",
      ],
    },
    {
      from: "May 2022",
      to: "August 2022",
      company: "KLASS Engineering & Solutions",
      position: "Software Engineer (Video Analytics)",
      type: "Contract",
      description:
        "Engineered a robust person reidentification pipeline and deployed it via Kubernetes (k3s) to support seamless, horizontal deployment in an air-gapped setting.",
      stack: ["Kubernetes", "Docker", "Python", "Machine Learning", "Milvus"],
    },
    {
      from: "August 2023",
      to: "April 2024",
      company: "Singapore Management University",
      position: "Computational Thinking Teaching Assistant",
      type: "Contract",
      description:
        "Provided guidance to over 50 students across 2 semesters on fundamental data structures and algorithms.",
      stack: [],
    },
    {
      from: "May 2024",
      to: "Current",
      company: "Accenture (Southeast Asia)",
      position: "Software Engineer",
      type: "Internship",
      description:
        "Contributing to the development and migration of the Citizen Disbursement IT platform in a cloud-based environment.",
      stack: ["Spring Boot", "Spring Batch", "AWS"],
    },
  ];

  return (
    <Listing
      list={experiences}
      title={"Experiences"}
      Component={IndividualExperience}
    />
  );
};

export default ExperienceListing;
