import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-tailwind/react";

const Listing = ({ list, title, Component }) => {
  return (
    <div className="pt-10">
      <Typography variant="h3" color="black" className="lg:hidden">
        {title}
      </Typography>
      <div className="px-4">
        {[...list].reverse().map((item, index) => (
          <Component key={index} {...item} />
        ))}
      </div>
    </div>
  );
};

Listing.propTypes = {
  list: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  Component: PropTypes.elementType.isRequired,
};

export default Listing;
