import React from "react";
import { Icon } from "@iconify/react";

const SocialIcons = () => {
  return (
    <div className="mt-5 flex justify-center gap-4 text-blue-gray-900 lg:justify-normal">
      <a
        href="https://www.linkedin.com/in/yue-zheng-ting/"
        target="_blank"
        rel="noreferrer"
      >
        <Icon icon="grommet-icons:linkedin" width="28" height="28" />
      </a>
      <a href="https://github.com/ztdevelops/" target="_blank" rel="noreferrer">
        <Icon icon="bi:github" width="28" height="28" />
      </a>
    </div>
  );
};

export default SocialIcons;
