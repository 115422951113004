import React from "react";
import { Typography } from "@material-tailwind/react";

import SocialIcons from "../SocialIcons/SocialIcons";

const Contact = () => {
  const email = "yuezhengting@gmail.com";

  return (
    <div className="my-10 scale-90 px-4 lg:scale-100 lg:text-left">
      <Typography className="text-gray-600">
        <p>
          Loosely designed in{" "}
          <span className="font-bold text-gray-700">Figma</span>,
        </p>
        <p>
          built with <span className="font-bold text-gray-700">React.js</span>{" "}
          and <span className="font-bold text-gray-700">Material Tailwind</span>
          ,
        </p>
        <p>
          hosted using{" "}
          <span className="inline font-bold text-gray-700">S3</span>,{" "}
          <span className="inline font-bold text-gray-700">Route 53</span>, and{" "}
          <span className="inline font-bold text-gray-700">Cloudfront</span>.
        </p>
      </Typography>

      <Typography variant="h3" color="gray" className="my-10">
        Get in touch!
        <Typography>
          Thank you for taking the time to look at my portfolio.
          <p>
            If you would like to get in touch, please feel free to email me at{" "}
            <a
              href={`mailto:${email}`}
              className="font-semibold hover:underline"
            >
              {email}
            </a>
            , or connect with me via my socials!
          </p>
        </Typography>
      </Typography>

      <SocialIcons />
    </div>
  );
};

export default Contact;
